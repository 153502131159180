<template>
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 512.001 512.001"
      style="enable-background: new 0 0 512.001 512.001"
      xml:space="preserve"
      :style="iconStyle"
    >
      <path
        style="fill: #f29f26"
        d="M350.012,150.789c-24.43,0-44.304-19.876-44.304-44.305c0-1.538-1.246-2.784-2.784-2.784  c-1.538,0-2.784,1.246-2.784,2.784c0,24.431-19.876,44.305-44.305,44.305c-1.538,0-2.784,1.246-2.784,2.784  c0,1.538,1.246,2.784,2.784,2.784c24.43,0,44.305,19.876,44.305,44.304c0,1.538,1.246,2.784,2.784,2.784  c1.538,0,2.784-1.246,2.784-2.784c0-24.43,19.876-44.304,44.304-44.304c1.538,0,2.784-1.246,2.784-2.784  C352.797,152.035,351.549,150.789,350.012,150.789z"
      />
      <path
        style="fill: #ef8229"
        d="M297.922,153.572c7.083-6.187,12.197-14.573,14.23-24.099c-4.087-6.706-6.445-14.578-6.445-22.991  c0-1.538-1.246-2.784-2.784-2.784c-1.538,0-2.784,1.246-2.784,2.784c0,15.747-8.266,29.593-20.68,37.451  c-1.677,1.061-3.431,2.013-5.25,2.847c-0.067,0.03-0.134,0.06-0.2,0.091c-1.782,0.806-3.626,1.497-5.523,2.065  c-0.131,0.039-0.264,0.075-0.395,0.112c-0.804,0.233-1.618,0.441-2.441,0.628c-0.165,0.038-0.329,0.079-0.493,0.115  c-0.924,0.199-1.858,0.371-2.802,0.511c-0.163,0.023-0.327,0.04-0.49,0.062c-0.777,0.107-1.563,0.192-2.352,0.256  c-0.271,0.022-0.542,0.045-0.814,0.062c-0.912,0.059-1.831,0.098-2.758,0.1c-0.036,0-0.071,0.002-0.107,0.002  c-1.538,0-2.784,1.246-2.784,2.784l0,0l0,0c0,1.538,1.246,2.784,2.784,2.784c0.036,0,0.071,0.002,0.107,0.002  c0.927,0.002,1.844,0.041,2.758,0.1c0.272,0.018,0.544,0.04,0.814,0.062c0.79,0.066,1.575,0.15,2.352,0.256  c0.163,0.022,0.327,0.039,0.49,0.062c0.944,0.14,1.878,0.312,2.802,0.511c0.166,0.036,0.329,0.077,0.493,0.115  c0.822,0.187,1.635,0.395,2.439,0.628c0.133,0.038,0.267,0.074,0.399,0.114c1.897,0.568,3.74,1.259,5.522,2.065  c0.067,0.03,0.134,0.06,0.2,0.09c1.819,0.834,3.573,1.784,5.25,2.847c12.414,7.86,20.68,21.704,20.68,37.451  c0,1.538,1.246,2.784,2.784,2.784c1.538,0,2.784-1.246,2.784-2.784c0-8.413,2.358-16.284,6.445-22.991  C310.12,168.146,305.006,159.759,297.922,153.572z"
      />
      <g>
        <circle style="fill: #f29f26" cx="381.94" cy="249.341" r="18.807" />
        <circle style="fill: #f29f26" cx="41.155" cy="467.631" r="18.807" />
      </g>
      <path
        style="fill: #ede21b"
        d="M292.14,368.183c-85.798-28.38-147.876-109.343-147.876-204.53c0-47.648,15.258-92.809,44.12-130.593  c3.777-4.945,3.702-11.823-0.179-16.687c-3.881-4.863-10.574-6.462-16.231-3.87c-43.949,20.1-81.214,52.145-107.765,92.666  c-27.257,41.598-41.662,89.98-41.662,139.918c0,140.96,114.68,255.64,255.64,255.64c49.939,0,98.321-14.407,139.919-41.662  c40.521-26.551,72.565-63.816,92.666-107.766c2.588-5.659,0.992-12.351-3.87-16.233c-4.863-3.88-11.743-3.954-16.687-0.178  c-37.784,28.864-82.943,44.12-130.593,44.12c-13.181,0-26.091-1.191-38.627-3.468"
      />
      <path
        style="fill: #eac71c"
        d="M441.467,435.824c-41.599,27.256-89.98,41.662-139.919,41.662c-140.96,0-255.64-114.68-255.64-255.64  c0-49.938,14.406-98.321,41.662-139.918c6.787-10.358,14.277-20.157,22.396-29.349C92.499,67.853,77.089,85.51,64.207,105.171  c-27.257,41.598-41.662,89.98-41.662,139.918c0,140.96,114.68,255.64,255.64,255.64c49.939,0,98.321-14.407,139.919-41.662  c19.289-12.64,36.653-27.711,51.726-44.771C460.924,422.081,451.454,429.28,441.467,435.824z"
      />
      <g>
        <circle style="fill: #ede21b" cx="302.922" cy="260.512" r="11.171" />
        <circle style="fill: #ede21b" cx="11.171" cy="394.638" r="11.171" />
        <circle style="fill: #ede21b" cx="374.556" cy="87.861" r="11.171" />
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    size: { type: Number, default: 24 },
  },
  computed: {
    iconStyle() {
      return {
        width: `${this.size}px`,
        height: `${this.size}px`,
      };
    },
  },
};
</script>

<style></style>
