<template>
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      style="enable-background: new 0 0 512 512"
      xml:space="preserve"
      :style="iconStyle"
    >
      <g>
        <path
          style="fill: #9fdef7"
          d="M348.078,243.4h16.578c33.779,0,61.163-27.383,61.163-61.162c0-0.363-0.022-0.721-0.028-1.083   H313.416c-21.715,0-39.319-17.606-39.319-39.319s17.603-39.316,39.319-39.316c3.416,0,6.727,0.438,9.888,1.254   c4.15-5.99,10.335-10.457,17.553-12.419c-11.919-30.74-41.757-52.546-76.707-52.546c-39.177,0-71.935,27.392-80.221,64.063   c-4.916-1.641-10.169-2.541-15.637-2.541c-16.831,0-31.684,8.428-40.593,21.288c-4.972-1.285-10.189-1.974-15.561-1.974   c-34.177,0-61.88,27.703-61.88,61.878c0,9.237,2.056,17.985,5.688,25.856c6.389-23.888,28.172-41.482,54.074-41.482   c26.662,0,48.952,18.641,54.591,43.594c3.346-1.115,6.921-1.729,10.644-1.729c11.451,0,21.561,5.736,27.624,14.487   c3.383-0.874,6.932-1.343,10.589-1.343c16.172,0,30.208,9.122,37.263,22.495h65.024"
        />
        <path
          style="fill: #9fdef7"
          d="M448.812,330.197c-1.865,0-3.744-0.582-5.35-1.788c-3.937-2.957-4.73-8.548-1.773-12.485   l15.627-20.798c2.959-3.935,8.548-4.729,12.486-1.772c3.937,2.957,4.73,8.548,1.773,12.485l-15.627,20.798   C454.195,328.968,451.52,330.197,448.812,330.197z"
        />
        <path
          style="fill: #9fdef7"
          d="M413.002,377.862c-1.865,0-3.744-0.582-5.35-1.788c-3.937-2.957-4.73-8.548-1.773-12.485   l15.627-20.798c2.959-3.936,8.548-4.729,12.486-1.772c3.937,2.957,4.73,8.548,1.773,12.485l-15.627,20.798   C418.385,376.633,415.71,377.862,413.002,377.862z"
        />
        <path
          style="fill: #9fdef7"
          d="M377.192,425.526c-1.865,0-3.744-0.583-5.35-1.789c-3.937-2.957-4.73-8.547-1.773-12.485   l15.627-20.799c2.958-3.935,8.549-4.729,12.485-1.772c3.938,2.957,4.732,8.547,1.775,12.485l-15.627,20.799   C382.577,424.298,379.901,425.526,377.192,425.526z"
        />
        <path
          style="fill: #9fdef7"
          d="M341.382,473.192c-1.865,0-3.744-0.581-5.349-1.787c-3.938-2.958-4.732-8.548-1.775-12.485   l15.625-20.799c2.958-3.937,8.549-4.732,12.485-1.775c3.938,2.958,4.732,8.548,1.775,12.485l-15.625,20.799   C346.766,471.964,344.091,473.192,341.382,473.192z"
        />
        <path
          style="fill: #9fdef7"
          d="M85.146,377.862c-1.865,0-3.743-0.581-5.35-1.787c-3.937-2.958-4.73-8.548-1.773-12.485   l15.625-20.798c2.957-3.938,8.548-4.732,12.486-1.775c3.937,2.958,4.73,8.548,1.773,12.485l-15.625,20.798   C90.529,376.633,87.853,377.862,85.146,377.862z"
        />
        <path
          style="fill: #9fdef7"
          d="M49.334,425.526c-1.865,0-3.744-0.583-5.35-1.789c-3.937-2.957-4.73-8.547-1.772-12.485   l15.627-20.799c2.957-3.936,8.547-4.729,12.485-1.772c3.937,2.957,4.73,8.547,1.772,12.485l-15.627,20.799   C54.717,424.298,52.041,425.526,49.334,425.526z"
        />
        <path
          style="fill: #9fdef7"
          d="M13.524,473.192c-1.865,0-3.744-0.582-5.35-1.788c-3.937-2.957-4.73-8.547-1.772-12.485   l15.626-20.799c2.957-3.937,8.548-4.73,12.485-1.772c3.937,2.957,4.73,8.547,1.772,12.485l-15.626,20.799   C18.907,471.964,16.232,473.192,13.524,473.192z"
        />
        <path
          style="fill: #9fdef7"
          d="M286.162,330.197c-1.865,0-3.744-0.582-5.35-1.788c-3.937-2.957-4.73-8.548-1.772-12.485   l15.626-20.798c2.958-3.937,8.549-4.73,12.486-1.772c3.937,2.957,4.73,8.548,1.772,12.485l-15.627,20.798   C291.547,328.968,288.871,330.197,286.162,330.197z"
        />
        <path
          style="fill: #9fdef7"
          d="M250.354,377.862c-1.865,0-3.744-0.582-5.35-1.788c-3.937-2.957-4.73-8.548-1.772-12.485   l15.627-20.798c2.957-3.936,8.548-4.729,12.485-1.772s4.73,8.548,1.772,12.485l-15.627,20.798   C255.736,376.633,253.061,377.862,250.354,377.862z"
        />
        <path
          style="fill: #9fdef7"
          d="M214.544,425.526c-1.865,0-3.744-0.583-5.35-1.789c-3.937-2.957-4.73-8.547-1.773-12.485   l15.627-20.799c2.958-3.936,8.549-4.729,12.486-1.772c3.937,2.957,4.73,8.547,1.773,12.485l-15.627,20.799   C219.928,424.298,217.252,425.526,214.544,425.526z"
        />
        <path
          style="fill: #9fdef7"
          d="M178.734,473.192c-1.865,0-3.744-0.581-5.349-1.787c-3.938-2.958-4.732-8.548-1.774-12.485   l15.625-20.799c2.958-3.937,8.548-4.732,12.485-1.775c3.938,2.958,4.732,8.548,1.774,12.485l-15.625,20.799   C184.118,471.964,181.442,473.192,178.734,473.192z"
        />
      </g>
      <path
        style="fill: #d7edf9"
        d="M472.756,103.427c-4.056,0-7.964,0.622-11.638,1.774c0.022-0.587,0.046-1.179,0.046-1.774  c0-28.869-23.403-52.273-52.272-52.273c-24.894,0-45.709,17.405-50.972,40.706c-3.124-1.041-6.462-1.613-9.936-1.613  c-10.694,0-20.134,5.356-25.794,13.526c-3.161-0.816-6.473-1.254-9.888-1.254c-21.715,0-39.319,17.603-39.319,39.316  s17.603,39.319,39.319,39.319h160.454c21.461,0,38.862-17.398,38.862-38.863C511.618,120.826,494.217,103.427,472.756,103.427z"
      />
      <path
        style="fill: #f29f26"
        d="M340.989,390.039c-0.473-1.009-1.498-1.662-2.614-1.662c-0.057,0-0.113,0.003-0.168,0.01l-24.44,3.02  l17.541-49.653c1.781-5.039,1.742-6.613,1.1-7.517c-0.337-0.477-0.866-0.75-1.455-0.75c-0.496,0-2.006,0-5.904,5.331l-55.438,75.176  c-0.041,0.058-0.078,0.115-0.11,0.177c-0.467,0.889-0.434,1.975,0.087,2.835c0.519,0.861,1.466,1.393,2.47,1.393  c0.041,0,0.082-0.002,0.124-0.007l21.797-1.992l-20.499,52.559c-0.519,1.325-0.006,2.814,1.224,3.54  c0.444,0.261,0.951,0.399,1.464,0.399c0.864,0,1.674-0.381,2.244-1.068l62.186-78.722  C341.308,392.25,341.46,391.045,340.989,390.039z"
      />
      <path
        style="fill: #ef8229"
        d="M272.058,418.399c0.041,0,0.082-0.002,0.124-0.007l21.797-1.992l-20.499,52.559  c-0.519,1.325-0.006,2.814,1.224,3.54c0.07,0.039,0.142,0.072,0.213,0.107l39.543-69.799l-21.797,1.992  c-0.041,0.004-0.082,0.007-0.124,0.007c-1.003,0-1.949-0.534-2.47-1.392c-0.521-0.859-0.553-1.945-0.087-2.836  c0.031-0.062,0.069-0.121,0.11-0.176l41.781-66.654c-0.272-0.164-0.584-0.262-0.92-0.262c-0.496,0-2.006,0-5.904,5.331  l-55.438,75.176c-0.041,0.058-0.078,0.115-0.11,0.177c-0.467,0.889-0.434,1.975,0.087,2.835  C270.107,417.865,271.055,418.399,272.058,418.399z"
      />
      <path
        style="fill: #f29f26"
        d="M511.73,390.039c-0.473-1.009-1.499-1.662-2.615-1.662c-0.057,0-0.113,0.003-0.167,0.01l-24.441,3.02  l17.541-49.653c1.78-5.039,1.742-6.613,1.1-7.517c-0.337-0.477-0.866-0.75-1.455-0.75c-0.496,0-2.006,0-5.904,5.331l-55.438,75.176  c-0.041,0.058-0.078,0.115-0.111,0.177c-0.466,0.889-0.434,1.975,0.087,2.835c0.521,0.861,1.466,1.393,2.47,1.393  c0.042,0,0.084-0.002,0.125-0.007l21.797-1.992l-20.499,52.559c-0.521,1.325-0.007,2.814,1.224,3.54  c0.444,0.261,0.951,0.399,1.464,0.399c0.864,0,1.674-0.381,2.243-1.068l62.187-78.722  C512.048,392.25,512.201,391.045,511.73,390.039z"
      />
      <path
        style="fill: #ef8229"
        d="M442.798,418.399c0.042,0,0.084-0.002,0.125-0.007l21.797-1.992L444.22,468.96  c-0.521,1.325-0.007,2.814,1.224,3.54c0.069,0.039,0.142,0.072,0.213,0.107l39.544-69.799l-21.797,1.992  c-0.041,0.004-0.082,0.007-0.125,0.007c-1.002,0-1.949-0.534-2.469-1.392c-0.521-0.859-0.554-1.945-0.088-2.836  c0.032-0.062,0.07-0.121,0.111-0.176l41.781-66.654c-0.273-0.164-0.584-0.262-0.92-0.262c-0.496,0-2.006,0-5.904,5.331  l-55.438,75.176c-0.041,0.058-0.078,0.115-0.111,0.177c-0.466,0.889-0.434,1.975,0.087,2.835  C440.849,417.865,441.794,418.399,442.798,418.399z"
      />
      <path
        style="fill: #f29f26"
        d="M170.249,390.039c-0.473-1.009-1.499-1.662-2.615-1.662c-0.057,0-0.111,0.003-0.167,0.01  l-24.441,3.02l17.541-49.653c1.78-5.039,1.742-6.613,1.1-7.517c-0.337-0.477-0.866-0.75-1.455-0.75c-0.496,0-2.006,0-5.904,5.331  L98.87,413.994c-0.041,0.058-0.078,0.115-0.111,0.177c-0.466,0.889-0.432,1.975,0.088,2.835c0.519,0.861,1.465,1.393,2.469,1.393  c0.042,0,0.084-0.002,0.125-0.007l21.797-1.992l-20.499,52.559c-0.521,1.325-0.006,2.814,1.224,3.54  c0.444,0.261,0.951,0.399,1.464,0.399c0.864,0,1.674-0.381,2.244-1.068l62.186-78.722  C170.566,392.25,170.72,391.045,170.249,390.039z"
      />
      <path
        style="fill: #ef8229"
        d="M101.317,418.399c0.042,0,0.084-0.002,0.125-0.007l21.797-1.992l-20.499,52.559  c-0.521,1.325-0.006,2.814,1.224,3.54c0.069,0.039,0.142,0.072,0.213,0.107l39.544-69.799l-21.797,1.992  c-0.041,0.004-0.082,0.007-0.125,0.007c-1.002,0-1.949-0.534-2.469-1.392c-0.521-0.859-0.554-1.945-0.088-2.836  c0.032-0.062,0.07-0.121,0.111-0.176l41.781-66.654c-0.273-0.164-0.584-0.262-0.92-0.262c-0.496,0-2.006,0-5.904,5.331  l-55.438,75.176c-0.041,0.058-0.078,0.115-0.111,0.177c-0.466,0.889-0.432,1.975,0.088,2.835  C99.367,417.865,100.313,418.399,101.317,418.399z"
      />
      <path
        style="fill: #83d4ed"
        d="M120.464,120.205c-2.724-0.367-5.504-0.562-8.326-0.562c-34.177,0-61.88,27.703-61.88,61.878  c0,9.237,2.056,17.985,5.688,25.856c5.99-22.394,25.508-39.257,49.266-41.278C107.582,149.687,112.837,134.209,120.464,120.205z"
      />
      <g>
        <path
          style="fill: #e4f2f9"
          d="M472.756,103.427c-4.056,0-7.964,0.622-11.638,1.774c0.022-0.587,0.046-1.179,0.046-1.774   c0-28.869-23.403-52.273-52.272-52.273c-20.713,0-38.592,12.054-47.049,29.522c-1.25,6.206-1.909,12.625-1.909,19.2   c0,34.092,17.645,64.06,44.297,81.279h68.525c21.461,0,38.862-17.398,38.862-38.863   C511.618,120.826,494.217,103.427,472.756,103.427z"
        />
        <path
          style="fill: #e4f2f9"
          d="M41.623,220.762c4.343,0,8.527,0.669,12.464,1.9c-0.022-0.631-0.048-1.263-0.048-1.9   c0-30.918,25.064-55.982,55.982-55.982c26.662,0,48.952,18.641,54.591,43.594c3.346-1.115,6.921-1.729,10.644-1.729   c11.451,0,21.561,5.736,27.624,14.487c3.383-0.874,6.932-1.343,10.589-1.343c23.257,0,42.11,18.853,42.11,42.109   c0,23.255-18.853,42.109-42.11,42.109H41.623C18.636,304.008,0,285.373,0,262.386C0,239.397,18.636,220.762,41.623,220.762z"
        />
      </g>
      <path
        style="fill: #d7edf9"
        d="M103.782,185.939c0-7.124,0.541-14.12,1.585-20.952c-28.74,2.366-51.33,26.425-51.33,55.774  c0,0.638,0.026,1.27,0.048,1.9c-3.936-1.232-8.12-1.9-12.464-1.9C18.636,220.762,0,239.397,0,262.386  c0,22.987,18.636,41.621,41.623,41.621h129.011C130.582,279.936,103.782,236.071,103.782,185.939z"
      />
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    size: { type: Number, default: 24 },
  },
  computed: {
    iconStyle() {
      return {
        width: `${this.size}px`,
        height: `${this.size}px`,
      };
    },
  },
};
</script>

<style></style>
