<template>
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      style="enable-background: new 0 0 512 512"
      xml:space="preserve"
      :style="iconStyle"
    >
      <path
        style="fill: #ed5145"
        d="M246.851,102.211c-103.621,0-188.439,80.438-195.453,182.273c0.273,0.08,0.553,0.141,0.822,0.225  c-0.021-0.61-0.046-1.222-0.046-1.835c0-29.853,24.199-54.053,54.052-54.053c25.742,0,47.265,17.999,52.709,42.094  c3.23-1.078,6.682-1.67,10.274-1.67c3.083,0,6.06,0.444,8.884,1.248c-0.007-0.002-0.016-0.006-0.022-0.008  c10.965-27.236,37.616-46.473,68.779-46.473c30.146,0,56.069,18.004,67.655,43.838c-0.013,0.019-0.023,0.038-0.037,0.058  c12.651-18.61,33.983-30.839,58.183-30.839c36.912,0,67.162,28.437,70.088,64.594c0.02-1.172,0.044-2.342,0.044-3.521  C442.784,189.934,355.06,102.211,246.851,102.211z"
      />
      <path
        style="fill: #f75c54"
        d="M246.851,102.211c-8.21,0-16.3,0.509-24.244,1.489c-7.17,18.311-11.11,38.242-11.11,59.094  c0,22.941,4.775,44.763,13.366,64.543c6.947-2.157,14.329-3.324,21.987-3.324c30.146,0,56.069,18.004,67.655,43.838  c-0.013,0.019-0.023,0.038-0.037,0.058c12.651-18.61,33.983-30.839,58.183-30.839c36.912,0,67.162,28.437,70.088,64.594  c0.02-1.172,0.044-2.342,0.044-3.521C442.784,189.934,355.06,102.211,246.851,102.211z"
      />
      <path
        style="fill: #f29f26"
        d="M334.48,169.845c-24.944-17.074-55.119-27.06-87.63-27.06c-60.905,0-113.612,35.049-139.08,86.072  c25.054,0.705,45.83,18.45,51.164,42.061c3.23-1.078,6.682-1.67,10.274-1.67c3.083,0,6.06,0.444,8.884,1.248  c-0.007-0.002-0.016-0.006-0.022-0.008c10.965-27.236,37.616-46.473,68.779-46.473c30.146,0,56.069,18.004,67.655,43.838  c-0.013,0.019-0.023,0.038-0.037,0.058c12.651-18.61,33.983-30.839,58.183-30.839c6.247,0,12.299,0.824,18.065,2.352  c-7.891-19.317-19.559-36.689-34.086-51.2"
      />
      <path
        style="fill: #f9a83d"
        d="M356.629,188.224l-22.148-18.379c-24.944-17.074-55.119-27.06-87.63-27.06  c-11.879,0-23.441,1.351-34.556,3.876c-0.524,5.308-0.797,10.689-0.797,16.134c0,22.941,4.775,44.763,13.366,64.543  c6.947-2.157,14.329-3.324,21.987-3.324c30.146,0,56.069,18.004,67.655,43.838c-0.013,0.019-0.023,0.038-0.037,0.058  c12.651-18.61,33.983-30.839,58.183-30.839c6.247,0,12.299,0.824,18.065,2.352C382.824,220.107,371.157,202.734,356.629,188.224z"
      />
      <path
        style="fill: #58c445"
        d="M246.851,184.739c-44.23,0-82.596,25.078-101.675,61.785c6.703,6.97,11.552,15.735,13.76,25.503  c3.23-1.078,6.682-1.67,10.274-1.67c3.083,0,6.06,0.444,8.884,1.248c-0.007-0.002-0.016-0.006-0.022-0.008  c10.965-27.236,37.616-46.473,68.779-46.473c30.146,0,56.069,18.004,67.655,43.838c-0.028,0.042-0.056,0.088-0.084,0.13  c7.861-11.587,19.083-20.707,32.27-25.95C327.058,208.285,289.706,184.739,246.851,184.739z"
      />
      <path
        style="fill: #6ad354"
        d="M246.851,184.739c-11.588,0-22.766,1.736-33.307,4.937c2.166,13.56,6.013,26.556,11.32,38.773  c6.947-2.157,14.329-3.324,21.987-3.324c30.146,0,56.069,18.004,67.655,43.838c-0.028,0.042-0.056,0.088-0.084,0.13  c7.861-11.587,19.083-20.707,32.27-25.95C327.058,208.285,289.706,184.739,246.851,184.739z"
      />
      <path
        style="fill: #83d4ed"
        d="M155.163,362.139h34.776c-0.107-1.371-0.177-2.753-0.177-4.152c0-29.221,23.688-52.909,52.91-52.909  c0.217,0,0.431,0.018,0.646,0.02c-6.303-14.293-20.587-24.273-37.212-24.273c-3.532,0-6.958,0.451-10.224,1.297  c-5.854-8.451-15.614-13.987-26.673-13.987c-3.593,0-7.045,0.592-10.274,1.67c-5.444-24.095-26.967-42.094-52.709-42.094  c-29.853,0-54.052,24.201-54.052,54.053c0,0.614,0.024,1.226,0.046,1.835c-3.798-1.191-7.84-1.835-12.033-1.835  C17.991,281.764,0,299.755,0,321.951c0,22.195,17.991,40.189,40.187,40.189h86.469"
      />
      <path
        style="fill: #9fdef7"
        d="M206.106,280.826c-3.532,0-6.958,0.451-10.224,1.297c-5.854-8.451-15.614-13.987-26.673-13.987  c-3.593,0-7.045,0.592-10.274,1.67c-5.444-24.095-26.967-42.094-52.709-42.094c-3.416,0-6.755,0.331-9.996,0.938  c-7.388,13.753-11.584,29.477-11.584,46.182c0,38.165,21.878,71.216,53.774,87.308h16.742h34.776  c-0.107-1.371-0.177-2.753-0.177-4.152c0-29.221,23.688-52.909,52.91-52.909c0.217,0,0.431,0.018,0.646,0.02  C237.015,290.806,222.731,280.826,206.106,280.826z"
      />
      <path
        style="fill: #d7edf9"
        d="M446.064,409.789h13.275c29.084,0,52.66-23.579,52.66-52.662c0-29.085-23.576-52.659-52.66-52.659  c-5.493,0-10.789,0.843-15.767,2.402c0.028-0.797,0.061-1.597,0.061-2.402c0-39.119-31.712-70.829-70.829-70.829  c-33.73,0-61.933,23.585-69.068,55.157c-4.232-1.413-8.755-2.189-13.462-2.189c-14.491,0-27.28,7.256-34.95,18.328  c-4.281-1.107-8.771-1.699-13.398-1.699c-29.424,0-53.276,23.852-53.276,53.275s23.852,53.277,53.276,53.277h174.315"
      />
      <path
        style="fill: #e4f2f9"
        d="M459.34,304.469c-5.493,0-10.789,0.843-15.767,2.402c0.028-0.797,0.061-1.597,0.061-2.402  c0-39.119-31.712-70.829-70.829-70.829c-33.73,0-61.933,23.585-69.068,55.157c-4.232-1.413-8.755-2.189-13.462-2.189  c-14.491,0-27.28,7.256-34.95,18.328c-0.485-0.125-0.982-0.22-1.473-0.332C295.719,366.18,366.344,406.63,446.418,406.63  c12.861,0,25.47-1.061,37.763-3.066C500.738,394.689,512,377.225,512,357.127C512,328.042,488.424,304.469,459.34,304.469z"
      />
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    size: { type: Number, default: 24 },
  },
  computed: {
    iconStyle() {
      return {
        width: `${this.size}px`,
        height: `${this.size}px`,
      };
    },
  },
};
</script>

<style></style>
