<template>
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      style="enable-background: new 0 0 512 512"
      xml:space="preserve"
      :style="iconStyle"
    >
      <path
        style="fill: #ede21b"
        d="M192.202,228.229c2.333,0,4.656-0.087,6.97-0.237c9.322-10.124,22.676-16.474,37.524-16.474  c3.657,0,7.221,0.393,10.661,1.124c0.844-0.519,1.687-1.039,2.519-1.586c0.704-0.462,1.396-0.944,2.089-1.423  c0.433-16.07,6.379-30.76,16.03-42.245c-12.886,7.006-27.254,10.671-42.226,10.671c-5.433,0-10.754-0.491-15.919-1.428  l-11.894-3.033c-35.365-11.697-60.952-45.069-60.952-84.304c0-19.639,6.29-38.255,18.185-53.828c1.557-2.039,1.525-4.875-0.073-6.88  c-1.601-2.003-4.358-2.663-6.691-1.594c-18.115,8.285-33.476,21.493-44.419,38.196c-11.236,17.146-17.174,37.087-17.174,57.672  C86.831,180.961,134.102,228.229,192.202,228.229z"
      />
      <path
        style="fill: #eac71c"
        d="M212.513,215.843c-1.436,0.061-2.873,0.104-4.318,0.104c-58.1,0-105.371-47.268-105.371-105.37  c0-20.585,5.938-40.527,17.173-57.672c4.676-7.139,10.167-13.631,16.327-19.382c-12.878,8.061-23.909,18.824-32.321,31.664  c-11.235,17.146-17.173,37.087-17.173,57.672c0,58.101,47.27,105.37,105.371,105.37c2.333,0,4.656-0.087,6.97-0.237  c3.825-4.153,8.335-7.658,13.342-10.364V215.843z"
      />
      <circle style="fill: #f29f26" cx="248.612" cy="79.591" r="18.301" />
      <path
        style="fill: #ef8229"
        d="M249.692,79.586c0-6.543,3.446-12.268,8.609-15.503c-2.813-1.763-6.129-2.798-9.693-2.798  c-10.105,0-18.301,8.194-18.301,18.301c0,10.107,8.196,18.303,18.301,18.303c3.564,0,6.881-1.035,9.693-2.798  C253.138,91.854,249.692,86.129,249.692,79.586z"
      />
      <path
        style="fill: #9fdef7"
        d="M446.305,209.239c-4.483,0-8.835,0.573-12.983,1.647c-7.431-10.729-19.827-17.762-33.869-17.762  c-4.562,0-8.945,0.751-13.047,2.121c-6.913-30.596-34.246-53.453-66.932-53.453c-37.91,0-68.64,30.73-68.64,68.64  c0,0.781,0.031,1.556,0.06,2.328c-4.824-1.512-9.958-2.328-15.281-2.328c-23.225,0-42.812,15.519-48.991,36.75  c10.453-6.443,22.763-10.167,35.946-10.167c37.908,0,68.64,30.732,68.64,68.64c0,0.781-0.034,1.555-0.06,2.328  c4.824-1.512,9.955-2.328,15.279-2.328c9.296,0,18.004,2.498,25.51,6.842h65.802h27.449h21.118  c28.517,0,51.631-23.116,51.631-51.631C497.935,232.354,474.821,209.239,446.305,209.239z"
      />
      <path
        style="fill: #f29f26"
        d="M223.387,10.873c0,6.005-4.868,10.871-10.873,10.871c-6.006,0-10.873-4.866-10.873-10.871  C201.64,4.866,206.507,0,212.513,0C218.518,0,223.387,4.866,223.387,10.873z"
      />
      <path
        style="fill: #83d4ed"
        d="M299.306,192.402c0-17.481,3.169-34.219,8.951-49.682c-32.573,5.355-57.425,33.623-57.425,67.713  c0,0.781,0.031,1.556,0.06,2.328c-4.824-1.512-9.957-2.328-15.281-2.328c-23.225,0-42.812,15.519-48.991,36.75  c10.453-6.443,22.763-10.167,35.946-10.167c37.908,0,68.64,30.732,68.64,68.64c0,0.781-0.034,1.555-0.06,2.328  c4.824-1.512,9.955-2.328,15.279-2.328c9.296,0,18.004,2.498,25.51,6.842h33.685C325.779,287.376,299.306,242.984,299.306,192.402z"
      />
      <g>
        <path
          style="fill: #9fdef7"
          d="M468.406,372.967c-1.812,0-3.638-0.566-5.2-1.738c-3.828-2.876-4.601-8.311-1.725-12.139   l15.192-20.223c2.877-3.828,8.311-4.601,12.139-1.725c3.828,2.876,4.601,8.311,1.725,12.139l-15.192,20.223   C473.642,371.772,471.041,372.967,468.406,372.967z"
        />
        <path
          style="fill: #9fdef7"
          d="M433.588,419.309c-1.812,0-3.639-0.565-5.201-1.737c-3.828-2.876-4.599-8.311-1.723-12.14   l15.192-20.221c2.875-3.826,8.31-4.599,12.139-1.724c3.828,2.876,4.599,8.311,1.723,12.14l-15.191,20.221   C438.824,418.115,436.223,419.309,433.588,419.309z"
        />
        <path
          style="fill: #9fdef7"
          d="M398.771,465.655c-1.812,0-3.638-0.565-5.2-1.737c-3.828-2.876-4.601-8.311-1.725-12.139   l15.192-20.223c2.876-3.828,8.311-4.601,12.139-1.725c3.828,2.876,4.601,8.311,1.725,12.139l-15.192,20.223   C404.006,464.459,401.405,465.655,398.771,465.655z"
        />
        <path
          style="fill: #9fdef7"
          d="M363.951,512c-1.812,0-3.639-0.566-5.201-1.738c-3.828-2.875-4.599-8.31-1.723-12.139l15.194-20.223   c2.875-3.826,8.311-4.598,12.139-1.723c3.828,2.875,4.599,8.31,1.723,12.139l-15.194,20.223   C369.187,510.805,366.586,512,363.951,512z"
        />
        <path
          style="fill: #9fdef7"
          d="M400.162,372.967c-1.812,0-3.639-0.566-5.2-1.738c-3.828-2.876-4.601-8.311-1.725-12.139   l15.192-20.223c2.877-3.828,8.311-4.601,12.139-1.725c3.828,2.876,4.601,8.311,1.725,12.139l-15.192,20.223   C405.398,371.772,402.796,372.967,400.162,372.967z"
        />
        <path
          style="fill: #9fdef7"
          d="M365.345,419.309c-1.812,0-3.639-0.565-5.201-1.737c-3.828-2.876-4.599-8.311-1.723-12.14   l15.192-20.221c2.875-3.826,8.31-4.599,12.139-1.724c3.828,2.876,4.599,8.311,1.723,12.14l-15.191,20.221   C370.58,418.115,367.978,419.309,365.345,419.309z"
        />
        <path
          style="fill: #9fdef7"
          d="M330.525,465.655c-1.812,0-3.639-0.566-5.201-1.738c-3.828-2.875-4.599-8.31-1.723-12.139   l15.194-20.223c2.875-3.826,8.311-4.599,12.139-1.723c3.828,2.875,4.599,8.31,1.723,12.139l-15.194,20.223   C335.761,464.459,333.158,465.655,330.525,465.655z"
        />
        <path
          style="fill: #9fdef7"
          d="M295.707,512c-1.812,0-3.639-0.566-5.201-1.738c-3.828-2.875-4.599-8.31-1.724-12.139l15.195-20.223   c2.875-3.826,8.31-4.598,12.139-1.723c3.828,2.875,4.599,8.31,1.723,12.139l-15.194,20.223   C300.943,510.805,298.341,512,295.707,512z"
        />
        <path
          style="fill: #9fdef7"
          d="M262.28,465.655c-1.812,0-3.639-0.566-5.201-1.738c-3.828-2.875-4.599-8.31-1.723-12.139   l15.194-20.223c2.875-3.826,8.311-4.599,12.139-1.723c3.828,2.875,4.599,8.31,1.723,12.139l-15.194,20.223   C267.516,464.459,264.915,465.655,262.28,465.655z"
        />
        <path
          style="fill: #9fdef7"
          d="M227.463,512c-1.812,0-3.638-0.565-5.2-1.737c-3.828-2.876-4.601-8.311-1.725-12.139l15.192-20.223   c2.877-3.828,8.311-4.601,12.139-1.725c3.828,2.876,4.601,8.311,1.725,12.139l-15.192,20.223   C232.698,510.805,230.097,512,227.463,512z"
        />
        <path
          style="fill: #9fdef7"
          d="M194.035,465.655c-1.812,0-3.639-0.566-5.201-1.738c-3.828-2.875-4.599-8.31-1.723-12.139   l15.194-20.223c2.875-3.826,8.311-4.599,12.139-1.723c3.828,2.875,4.599,8.31,1.723,12.139l-15.194,20.223   C199.271,464.459,196.67,465.655,194.035,465.655z"
        />
        <path
          style="fill: #9fdef7"
          d="M159.218,512c-1.812,0-3.638-0.565-5.2-1.737c-3.828-2.876-4.601-8.311-1.725-12.139l15.192-20.223   c2.877-3.828,8.311-4.601,12.139-1.725c3.828,2.876,4.601,8.311,1.725,12.139l-15.192,20.223   C164.453,510.805,161.852,512,159.218,512z"
        />
        <path
          style="fill: #9fdef7"
          d="M125.792,465.655c-1.812,0-3.639-0.566-5.201-1.738c-3.828-2.875-4.599-8.31-1.723-12.139   l15.194-20.223c2.875-3.826,8.311-4.599,12.139-1.723c3.828,2.875,4.599,8.31,1.723,12.139l-15.194,20.223   C131.027,464.459,128.425,465.655,125.792,465.655z"
        />
        <path
          style="fill: #9fdef7"
          d="M90.974,512c-1.812,0-3.638-0.565-5.2-1.737c-3.828-2.876-4.601-8.311-1.725-12.139l15.192-20.223   c2.876-3.828,8.311-4.601,12.139-1.725c3.828,2.876,4.601,8.311,1.725,12.139l-15.192,20.223C96.21,510.805,93.608,512,90.974,512z   "
        />
        <path
          style="fill: #9fdef7"
          d="M57.547,465.655c-1.812,0-3.638-0.565-5.2-1.737c-3.828-2.876-4.601-8.311-1.725-12.139   l15.192-20.223c2.876-3.828,8.311-4.601,12.139-1.725c3.828,2.876,4.601,8.311,1.725,12.139l-15.192,20.223   C62.783,464.459,60.182,465.655,57.547,465.655z"
        />
        <path
          style="fill: #9fdef7"
          d="M22.727,512c-1.812,0-3.639-0.566-5.201-1.738c-3.828-2.875-4.599-8.31-1.723-12.139L30.997,477.9   c2.875-3.826,8.31-4.598,12.139-1.723c3.828,2.875,4.599,8.31,1.723,12.139l-15.194,20.223C27.963,510.805,25.362,512,22.727,512z"
        />
      </g>
      <path
        style="fill: #e4f2f9"
        d="M282.486,406.639h25.023c28.186,0,51.034-22.848,51.034-51.032c0-28.186-22.848-51.034-51.034-51.034  c-5.323,0-10.454,0.817-15.279,2.328c0.025-0.773,0.06-1.548,0.06-2.328c0-37.908-30.732-68.64-68.64-68.64  c-32.688,0-60.019,22.856-66.935,53.453c-4.1-1.368-8.484-2.121-13.045-2.121c-14.044,0-26.438,7.031-33.871,17.764  c-4.149-1.073-8.499-1.649-12.984-1.649c-28.513,0-51.629,23.114-51.629,51.629s23.114,51.631,51.629,51.631h156.786"
      />
      <path
        style="fill: #d7edf9"
        d="M156.692,296.366c0-2.597,0.075-5.177,0.212-7.74c-0.062,0.254-0.131,0.504-0.19,0.76  c-4.1-1.368-8.484-2.121-13.045-2.121c-14.044,0-26.438,7.031-33.871,17.764c-4.149-1.073-8.499-1.649-12.984-1.649  c-28.513,0-51.629,23.114-51.629,51.629s23.114,51.631,51.629,51.631h112.488C177.211,380.627,156.692,340.896,156.692,296.366z"
      />
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    size: { type: Number, default: 24 },
  },
  computed: {
    iconStyle() {
      return {
        width: `${this.size}px`,
        height: `${this.size}px`,
      };
    },
  },
};
</script>

<style></style>
