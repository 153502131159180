<template>
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 511.998 511.998"
      style="enable-background: new 0 0 511.998 511.998"
      xml:space="preserve"
      :style="iconStyle"
    >
      <path
        style="fill: #9fdef7"
        d="M155.968,187.07h38.254c14.29-26.216,38.712-46.094,68.024-54.357  c-5.897-14.318-19.979-24.397-36.419-24.397c-4.11,0-8.069,0.633-11.79,1.798c0.02-0.598,0.046-1.195,0.046-1.798  c0-29.244-23.714-52.956-52.96-52.956c-25.223,0-46.309,17.632-51.642,41.239c-3.168-1.056-6.548-1.634-10.069-1.634  c-10.834,0-20.398,5.424-26.133,13.703c-3.2-0.825-6.56-1.272-10.019-1.272c-22,0-39.834,17.835-39.834,39.836  c0,21.997,17.835,39.836,39.834,39.836h63.687"
      />
      <path
        style="fill: #beeafc"
        d="M373.536,162.958c-20.468-21.893-49.607-35.578-81.941-35.578c-53.408,0-98.066,37.345-109.362,87.34  c-6.704-2.236-13.865-3.466-21.318-3.466c-22.947,0-43.196,11.49-55.341,29.022c-6.779-1.751-13.888-2.692-21.216-2.692  C37.768,237.584,0,275.352,0,321.942c0,46.588,37.768,84.361,84.358,84.361h93.011c0.196-28.675,23.489-51.858,52.206-51.858  c5.446,0,10.697,0.835,15.633,2.382c-0.023-0.79-0.059-1.582-0.059-2.382c0-38.787,31.442-70.23,70.228-70.23  c33.443,0,61.41,23.387,68.483,54.691c4.197-1.401,8.678-2.17,13.346-2.17c14.368,0,27.051,7.194,34.655,18.175  c4.245-1.098,8.696-1.688,13.284-1.688c19.231,0,36.057,10.279,45.295,25.637c13.396-14.793,21.557-34.414,21.557-55.94  c0-46.056-37.332-83.384-83.384-83.384c-8.696,0-17.08,1.333-24.963,3.803c0.041-1.263,0.096-2.529,0.096-3.803  c0-18.832-4.641-36.579-12.845-52.163"
      />
      <path
        style="fill: #aee4ff"
        d="M89.442,237.74c-1.682-0.102-3.376-0.157-5.084-0.157C37.768,237.582,0,275.351,0,321.941  c0,46.588,37.768,84.361,84.358,84.361h93.011c0.061-8.998,2.4-17.454,6.464-24.823C137.844,346.086,103.961,295.755,89.442,237.74z  "
      />
      <path
        style="fill: #83d4ed"
        d="M81.614,174.026c0-27.362,4.208-53.738,11.999-78.528c-8.402,1.551-15.646,6.407-20.333,13.171  c-3.2-0.825-6.56-1.272-10.019-1.272c-22,0-39.834,17.835-39.834,39.836c0,21.997,17.835,39.836,39.834,39.836h18.678  C81.727,182.747,81.614,178.4,81.614,174.026z"
      />
      <path
        style="fill: #e4f2f9"
        d="M445.146,350.991c-4.588,0-9.041,0.588-13.284,1.688c-7.604-10.982-20.287-18.175-34.655-18.175  c-4.668,0-9.149,0.769-13.346,2.17c-7.074-31.305-35.04-54.691-68.483-54.691c-38.786,0-70.228,31.443-70.228,70.23  c0,0.8,0.035,1.592,0.059,2.382c-4.936-1.547-10.187-2.382-15.633-2.382c-28.835,0-52.212,23.373-52.212,52.216  c0,28.835,23.378,52.21,52.212,52.21h156.329h29.013h30.23c29.175,0,52.827-23.651,52.827-52.824  C497.973,374.64,474.321,350.991,445.146,350.991z"
      />
      <path
        style="fill: #d7edf9"
        d="M285.258,337.525c0-18.847,4-36.758,11.185-52.941c-29.585,8.267-51.295,35.403-51.295,67.627  c0,0.8,0.035,1.592,0.059,2.382c-4.936-1.547-10.187-2.382-15.633-2.382c-28.835,0-52.212,23.373-52.212,52.216  c0,28.835,23.378,52.21,52.212,52.21h132.677C316.86,436.184,285.258,390.545,285.258,337.525z"
      />
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    size: { type: Number, default: 24 },
  },
  computed: {
    iconStyle() {
      return {
        width: `${this.size}px`,
        height: `${this.size}px`,
      };
    },
  },
};
</script>

<style></style>
