<template>
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 511.999 511.999"
      style="enable-background: new 0 0 511.999 511.999"
      xml:space="preserve"
      :style="iconStyle"
    >
      <g>
        <path
          style="fill: #9fdef7"
          d="M458.537,323.516c-1.848,0-3.71-0.576-5.301-1.771c-3.903-2.932-4.689-8.471-1.759-12.373   l15.485-20.611c2.932-3.902,8.47-4.689,12.373-1.759c3.903,2.932,4.689,8.471,1.759,12.373l-15.485,20.611   C463.872,322.299,461.22,323.516,458.537,323.516z"
        />
        <path
          style="fill: #9fdef7"
          d="M423.048,370.754c-1.848,0-3.711-0.576-5.301-1.771c-3.903-2.932-4.689-8.471-1.759-12.373   l15.484-20.611c2.932-3.902,8.47-4.69,12.373-1.759s4.689,8.471,1.759,12.373l-15.485,20.611   C428.383,369.536,425.731,370.754,423.048,370.754z"
        />
        <path
          style="fill: #9fdef7"
          d="M387.559,417.99c-1.848,0-3.71-0.577-5.301-1.772c-3.903-2.932-4.689-8.471-1.759-12.373   l15.485-20.613c2.932-3.902,8.471-4.689,12.373-1.759c3.903,2.932,4.689,8.471,1.759,12.373l-15.485,20.613   C392.895,416.773,390.243,417.99,387.559,417.99z"
        />
        <path
          style="fill: #9fdef7"
          d="M352.069,465.229c-1.848,0-3.71-0.578-5.301-1.773c-3.903-2.931-4.689-8.47-1.759-12.373   l15.487-20.612c2.932-3.899,8.473-4.687,12.373-1.756c3.903,2.931,4.689,8.47,1.759,12.373L359.139,461.7   C357.404,464.012,354.752,465.229,352.069,465.229z"
        />
        <path
          style="fill: #9fdef7"
          d="M390.614,323.516c-1.848,0-3.711-0.577-5.302-1.772c-3.902-2.931-4.688-8.471-1.757-12.373   l15.487-20.611c2.932-3.9,8.471-4.687,12.374-1.756c3.902,2.931,4.688,8.471,1.757,12.373l-15.487,20.611   C395.949,322.299,393.298,323.516,390.614,323.516z"
        />
        <path
          style="fill: #9fdef7"
          d="M355.125,370.754c-1.848,0-3.71-0.577-5.302-1.772c-3.902-2.931-4.688-8.471-1.757-12.373   l15.487-20.611c2.932-3.9,8.471-4.687,12.374-1.756c3.902,2.931,4.688,8.471,1.757,12.373l-15.487,20.611   C360.46,369.536,357.809,370.754,355.125,370.754z"
        />
        <path
          style="fill: #9fdef7"
          d="M319.636,417.99c-1.848,0-3.71-0.578-5.302-1.773c-3.902-2.931-4.688-8.47-1.757-12.373   l15.487-20.613c2.932-3.9,8.473-4.687,12.373-1.756c3.903,2.931,4.689,8.47,1.759,12.373l-15.487,20.613   C324.972,416.773,322.321,417.99,319.636,417.99z"
        />
        <path
          style="fill: #9fdef7"
          d="M284.148,465.229c-1.848,0-3.71-0.577-5.301-1.772c-3.903-2.932-4.689-8.471-1.759-12.373   l15.485-20.613c2.932-3.903,8.471-4.689,12.373-1.759c3.903,2.932,4.689,8.471,1.759,12.373l-15.485,20.612   C289.484,464.012,286.832,465.229,284.148,465.229z"
        />
        <path
          style="fill: #9fdef7"
          d="M322.694,323.516c-1.848,0-3.71-0.576-5.302-1.771c-3.902-2.932-4.688-8.471-1.758-12.373   l15.485-20.611c2.932-3.902,8.47-4.689,12.373-1.759c3.903,2.932,4.689,8.471,1.759,12.373l-15.485,20.611   C328.029,322.299,325.378,323.516,322.694,323.516z"
        />
        <path
          style="fill: #9fdef7"
          d="M287.205,370.754c-1.848,0-3.71-0.576-5.302-1.771c-3.902-2.932-4.688-8.471-1.758-12.373   l15.484-20.611c2.932-3.903,8.471-4.69,12.373-1.759c3.903,2.932,4.689,8.471,1.759,12.373l-15.485,20.611   C292.541,369.536,289.89,370.754,287.205,370.754z"
        />
        <path
          style="fill: #9fdef7"
          d="M251.715,417.99c-1.848,0-3.71-0.578-5.302-1.773c-3.902-2.931-4.688-8.47-1.757-12.373   l15.487-20.613c2.932-3.9,8.473-4.688,12.374-1.756c3.902,2.931,4.688,8.47,1.757,12.373l-15.487,20.613   C257.05,416.773,254.399,417.99,251.715,417.99z"
        />
        <path
          style="fill: #9fdef7"
          d="M216.226,465.229c-1.848,0-3.71-0.578-5.302-1.773c-3.902-2.931-4.688-8.47-1.757-12.373   l15.487-20.612c2.932-3.9,8.473-4.687,12.374-1.756c3.902,2.931,4.688,8.47,1.757,12.373L223.298,461.7   C221.561,464.012,218.91,465.229,216.226,465.229z"
        />
        <path
          style="fill: #9fdef7"
          d="M219.284,370.754c-1.848,0-3.71-0.576-5.301-1.771c-3.903-2.932-4.689-8.471-1.759-12.373   l15.485-20.611c2.932-3.903,8.471-4.69,12.373-1.759c3.903,2.932,4.689,8.471,1.759,12.373l-15.485,20.611   C224.619,369.536,221.967,370.754,219.284,370.754z"
        />
        <path
          style="fill: #9fdef7"
          d="M183.795,417.99c-1.848,0-3.71-0.577-5.301-1.772c-3.903-2.932-4.689-8.471-1.759-12.373   l15.485-20.613c2.932-3.902,8.471-4.689,12.373-1.759c3.902,2.932,4.689,8.471,1.759,12.373l-15.485,20.613   C189.13,416.773,186.478,417.99,183.795,417.99z"
        />
        <path
          style="fill: #9fdef7"
          d="M148.304,465.229c-1.848,0-3.71-0.578-5.302-1.773c-3.902-2.931-4.688-8.47-1.757-12.373   l15.487-20.612c2.932-3.9,8.473-4.687,12.373-1.756c3.903,2.931,4.689,8.47,1.759,12.373L155.376,461.7   C153.639,464.012,150.988,465.229,148.304,465.229z"
        />
        <path
          style="fill: #9fdef7"
          d="M151.361,370.754c-1.848,0-3.71-0.577-5.302-1.772c-3.902-2.931-4.688-8.471-1.756-12.373   l15.487-20.611c2.931-3.902,8.471-4.688,12.373-1.756c3.902,2.931,4.688,8.471,1.756,12.373l-15.487,20.611   C156.697,369.536,154.045,370.754,151.361,370.754z"
        />
        <path
          style="fill: #9fdef7"
          d="M115.872,417.99c-1.848,0-3.71-0.578-5.302-1.773c-3.902-2.931-4.688-8.47-1.757-12.373   l15.487-20.613c2.932-3.9,8.473-4.688,12.374-1.756c3.902,2.931,4.688,8.47,1.757,12.373l-15.487,20.613   C121.208,416.773,118.556,417.99,115.872,417.99z"
        />
        <path
          style="fill: #9fdef7"
          d="M80.384,465.229c-1.848,0-3.71-0.577-5.301-1.772c-3.903-2.932-4.689-8.471-1.759-12.373   l15.485-20.613c2.932-3.903,8.471-4.689,12.373-1.759c3.903,2.932,4.689,8.471,1.759,12.373l-15.485,20.612   C85.72,464.012,83.068,465.229,80.384,465.229z"
        />
        <path
          style="fill: #9fdef7"
          d="M83.441,370.754c-1.848,0-3.71-0.576-5.301-1.771c-3.903-2.932-4.689-8.471-1.759-12.373   l15.485-20.611c2.932-3.903,8.47-4.69,12.373-1.759s4.689,8.471,1.759,12.373l-15.485,20.611   C88.776,369.536,86.125,370.754,83.441,370.754z"
        />
        <path
          style="fill: #9fdef7"
          d="M47.95,417.99c-1.848,0-3.71-0.578-5.302-1.773c-3.902-2.931-4.688-8.47-1.757-12.373l15.487-20.613   c2.932-3.9,8.473-4.688,12.374-1.756c3.902,2.931,4.688,8.47,1.757,12.373l-15.487,20.613   C53.285,416.773,50.634,417.99,47.95,417.99z"
        />
        <path
          style="fill: #9fdef7"
          d="M12.461,465.229c-1.848,0-3.71-0.578-5.302-1.773c-3.902-2.931-4.688-8.47-1.757-12.373   l15.487-20.612c2.932-3.9,8.473-4.687,12.374-1.756s4.688,8.47,1.757,12.373L19.533,461.7   C17.797,464.012,15.146,465.229,12.461,465.229z"
        />
        <path
          style="fill: #9fdef7"
          d="M420.867,187.839H309.499c-21.519,0-38.965-17.448-38.965-38.966s17.446-38.964,38.965-38.964   c3.386,0,6.668,0.434,9.8,1.243c4.113-5.935,10.241-10.363,17.396-12.307C324.883,68.38,295.312,46.77,260.676,46.77   c-38.825,0-71.289,27.148-79.503,63.489c-4.869-1.625-10.078-2.517-15.495-2.517c-16.68,0-31.403,8.352-40.231,21.096   c-4.928-1.273-10.095-1.956-15.422-1.956c-33.868,0-61.325,27.454-61.325,61.323c0,9.827,2.325,19.109,6.436,27.342   c7.202-22.285,28.107-38.408,52.792-38.408c26.421,0,48.513,18.473,54.101,43.203c3.316-1.105,6.859-1.713,10.548-1.713   c11.348,0,21.368,5.684,27.376,14.356c3.353-0.865,6.87-1.331,10.494-1.331c14.175,0,26.69,7.072,34.233,17.875h67.916h32.034   h15.648c33.476,0,60.615-27.136,60.615-60.612C420.895,188.553,420.874,188.197,420.867,187.839z"
        />
      </g>
      <path
        style="fill: #e4f2f9"
        d="M472.182,109.304c-4.156,0-8.159,0.638-11.925,1.818c0.022-0.602,0.046-1.206,0.046-1.818  c0-29.577-23.978-53.557-53.558-53.557c-25.506,0-46.832,17.833-52.226,41.707c-3.201-1.066-6.62-1.653-10.18-1.653  c-10.958,0-20.628,5.487-26.427,13.859c-3.239-0.836-6.631-1.285-10.133-1.285c-22.248,0-40.285,18.037-40.285,40.284  s18.036,40.286,40.285,40.286h164.402c21.989,0,39.818-17.827,39.818-39.818C512,127.133,494.171,109.304,472.182,109.304z"
      />
      <path
        style="fill: #83d4ed"
        d="M175.392,108.717c-3.14-0.636-6.387-0.977-9.714-0.977c-16.68,0-31.403,8.352-40.231,21.096  c-4.928-1.273-10.095-1.956-15.422-1.956c-33.868,0-61.325,27.454-61.325,61.323c0,9.827,2.325,19.109,6.436,27.342  c7.202-22.285,28.107-38.408,52.792-38.408c26.421,0,48.513,18.473,54.101,43.203c3.316-1.105,6.859-1.713,10.548-1.713  c11.348,0,21.368,5.684,27.376,14.356c3.353-0.865,6.87-1.331,10.494-1.331c14.175,0,26.69,7.072,34.233,17.875h46.068  C227.104,232.753,179.448,176.611,175.392,108.717z"
      />
      <path
        style="fill: #d7edf9"
        d="M351.438,96.613c-2.285-0.518-4.655-0.813-7.097-0.813c-10.958,0-20.628,5.487-26.427,13.859  c-3.239-0.836-6.631-1.285-10.133-1.285c-22.248,0-40.285,18.036-40.285,40.284s18.036,40.286,40.285,40.286h102.382  C375.546,172.587,351.569,137.41,351.438,96.613z"
      />
      <path
        style="fill: #e4f2f9"
        d="M211.553,230.547c-3.624,0-7.141,0.466-10.494,1.331c-6.008-8.671-16.028-14.356-27.376-14.356  c-3.689,0-7.232,0.609-10.548,1.713c-5.588-24.732-27.681-43.203-54.101-43.203c-30.643,0-55.483,24.839-55.483,55.48  c0,0.632,0.025,1.258,0.048,1.883c-3.898-1.221-8.047-1.883-12.35-1.883C18.468,231.511,0,249.98,0,272.763  c0,22.781,18.468,41.247,41.249,41.247h109.073h29.824h31.407c23.048,0,41.731-18.684,41.731-41.73  C253.284,249.231,234.602,230.547,211.553,230.547z"
      />
      <path
        style="fill: #d7edf9"
        d="M68.463,193.685c-9.245,9.912-14.911,23.204-14.911,37.826c0,0.632,0.025,1.258,0.047,1.883  c-3.898-1.221-8.047-1.883-12.35-1.883C18.468,231.511,0,249.98,0,272.763c0,22.781,18.468,41.247,41.249,41.247h109.073h3.689  C104.564,296.189,69.096,249.125,68.463,193.685z"
      />
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    size: { type: Number, default: 24 },
  },
  computed: {
    iconStyle() {
      return {
        width: `${this.size}px`,
        height: `${this.size}px`,
      };
    },
  },
};
</script>

<style></style>
